import React, { useState } from "react";
import Cookies from "js-cookie";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import {
  AppBar,
  IconButton,
  Menu,
  MenuItem,
  Typography
} from "@material-ui/core";
import { AccountCircle } from "@material-ui/icons";
import MenuIcon from "@material-ui/icons/Menu";

import RBAC from "../HOC/Rbac";
import ROLES from "../routes/Role";
import { getCurrentUser } from "../helpers/AuthHelper";

export default function Header(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const isAdminAvailable = Cookies.get("admin-token");
  const currentUser = useSelector(getCurrentUser);
  const history = useHistory();
  const open = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openProfilePage = () => {
    history.push("/profile");
    handleClose();
  };

  return (
    <div>
      <AppBar position="fixed" className="header__section">
        <IconButton
          edge="start"
          className="menu__button"
          color="inherit"
          aria-label="menu"
          onClick={props.handleDrawerOpen}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" className="title">
          <RBAC allowedRoles={ROLES.admin}>
            {process.env.REACT_APP_SYSTEM === "candimix-redirect"
              ? "CANDIMIX"
              : process.env.REACT_APP_SYSTEM === "consigo"
              ? "CONSIGO"
              : "NINAYA"}
          </RBAC>
          <RBAC allowedRoles={ROLES.publisher}>
            {" "}
            {isAdminAvailable
              ? `Publisher Reporting: ${currentUser.publisher?.name}`
              : "Publisher Reporting"}
          </RBAC>
        </Typography>
        <RBAC allowedRoles={ROLES.admin}>
          <div>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
              open={open}
              onClose={handleClose}
            >
              <MenuItem onClick={openProfilePage}>Profile</MenuItem>
            </Menu>
          </div>
        </RBAC>
      </AppBar>
    </div>
  );
}
