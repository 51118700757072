import React from "react";
import Routes from "./routes";
import { Provider } from "react-redux";
import store from "./store";
import "./assets/scss/main.scss";
import { MuiThemeProvider, createTheme } from "@material-ui/core";
import FirebaseNotification from "./pages/Firebase/FirebaseNotification";

const system = process.env.REACT_APP_SYSTEM;

// changes root colors
const ninayaTheme = createTheme({
  palette: {
    primary: {
      main: "#4051b5"
    }
  }
});

const consigoTheme = createTheme({
  palette: {
    primary: {
      main: "#0389a8"
    }
  }
});

const candimixTheme = createTheme({
  palette: {
    primary: {
      main: "#228888"
    }
  }
});

function App() {
  if (system === "candimix-redirect") {
    document.documentElement.style.setProperty("--color-primary", "#228888");
    document.documentElement.style.setProperty("--color-primary-2", "#11adad");
    document.documentElement.style.setProperty(
      "--color-primary-focused",
      "#86c6d5"
    );
    document
      .querySelector('link[rel="icon"]')
      .setAttribute("href", "candimix.favicon.ico");
  }
  if (system === "consigo") {
    document.documentElement.style.setProperty("--color-primary", "#0389a8");
    document.documentElement.style.setProperty("--color-primary-2", "#63dcf8");
    document.documentElement.style.setProperty(
      "--color-primary-focused",
      "#86c6d5"
    );
    document
      .querySelector('link[rel="icon"]')
      .setAttribute("href", "consigo.favicon.ico");
  }
  return (
    <>
      <MuiThemeProvider
        theme={
          system === "candimix-redirect"
            ? candimixTheme
            : system === "consigo"
            ? consigoTheme
            : ninayaTheme
        }
      >
        <Provider store={store}>
          <Routes />
          <FirebaseNotification />
        </Provider>
      </MuiThemeProvider>
    </>
  );
}

export default App;
