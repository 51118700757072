import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";

/**
 * HOC to change the title of the tab
 * @param {*} Component _ React component
 * @param {string} title - Title of the page
 * @returns
 */
const withTitle = (Component, title = "") => {
  const system =
    process.env.REACT_APP_SYSTEM === "candimix-redirect"
      ? "Candimix"
      : process.env.REACT_APP_SYSTEM === "consigo"
      ? "Consigo"
      : "Ninaya";
  return () => (
    <HelmetProvider>
      <Helmet>
        <title>{`${title} | ${system}`}</title>
      </Helmet>
      <Component />
    </HelmetProvider>
  );
};

export default withTitle;
